"use client"
import React, { useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {toast } from 'sonner'
import {useForm} from 'react-hook-form';
import {hardGray, hover, softWhite, text, white} from '@/styles/globalStyleVars';
import Button from "@/components/common/ui/Button";
import {usePathname} from "next/navigation";
import ImageSocialBox from "@/components/common/ImageSocialBox";
import axios from "axios";
import TextInput from "@/components/common/ui/forms/TextInput";
import {errorToast, successToast} from "@/components/hooks/toastNotifications";
import Select, {components} from 'react-select';
import {useShowSetting} from "@/app/ShowSettingProvider";


const ContactForm = ({padding, data, id, pt,  leftTitle, subtitle}) => {

    const [offset, setOffset] = useState(90);
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});
    const location = usePathname();
    const {errors, isSubmitSuccessful} = formState;
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [selectResponseData, setSelectResponseData] = useState(null);
    const [selectContact, setSelectContact] = useState(null);
    const [selectInterest, setSelectInterest] = useState(null);
    const [selectMigration, setSelectMigration] = useState(null);
    const interestRef = useRef(null); // Create a separate ref for education
    const contactRef = useRef(null);
    const migrationRef = useRef(null);

    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft + 0);
    }, [location.pathname]);

    const handleContact = (e) => {
        setSelectContact(e?.label);
    };
    const handleInterest = (e) => {
        setSelectInterest(e?.label);
    };

    const handleMigration = (e) => {
        setSelectMigration(e?.label);
    };


    const onSubmit = async (e) => {

        setIsSubmitting(true); // Show spinner
        try {
            const formData = new FormData();
            formData.append('name', e?.name);
            formData.append('email', e?.email);
            formData.append('phone', e?.phone);
            formData.append('qualification', e?.qualification);
            formData.append('contact_method', selectContact);
            formData.append('interested', selectInterest);
            formData.append('migration', selectMigration);

            formData.append('form_id', 'ieilts-form');
            const response = await axios.post('https://cms.maximuseducation.com.au/api/post-req-data/form-submit', formData);

            if (response?.data?.result === 'success') {
                // successToast(response?.data?.message);
                setSelectResponseData(response)

                reset(); // This will reset the form fields
                clearSelectValue();
            } else if (response?.data?.result === 'error') {
                errorToast(response?.data?.message || 'An error occurred. Please try again.');
            }
        } catch (err) {
            errorToast(err?.message || 'Failed to submit form. Please try again later.');

        } finally {
            setIsSubmitting(false); // Hide spinner
        }
    };

    const onError = (errors) => {
        const count = Object.values(errors).length;
        if (count > 0) {
            toast.error('Please fill out the correct inputs');
        }
    };
    useEffect(() => {
        if (selectResponseData?.error) {
            errorToast(selectResponseData.error);
        }
        if (selectResponseData?.data?.result === 'success') {
            successToast(selectResponseData.data?.message);
        }
    }, [selectResponseData]);


    // const selectLocationInputRef = useRef();

    const clearSelectValue = () => {
        if (interestRef.current && contactRef.current && migrationRef.current) {
            interestRef.current.clearValue();
            contactRef.current.clearValue();
            migrationRef.current.clearValue();
        }
    };

    const contactType = [
        {value: 'Phone/WhatsApp', label: 'Phone/WhatsApp'},
        {value: 'Email', label: 'Email'},
    ];
    const interestType = [
        {value: 'IELTS', label: 'IELTS'},
        {value: 'PTE', label: 'PTE'},
    ];

    const migrationType = [
        {value: 'For Higher Study', label: 'For Higher Study'},
        {value: 'For Skilled Migration', label: 'For Skilled Migration'},
    ];

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s cubic-bezier(.74,0,.24,.99)',
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${white}` : text,
            backgroundColor: state.isSelected ? hover : white,
            margin: 0,
            fontSize: 16,
            cursor: 'pointer',
            lineHeight: '20px',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 400 : 400,
            borderBottom: state.options.indexOf(state.data) === state.options.length - 1 ? 'none' : '1px solid #888888', // Check if it's the last item

            '&:hover': {
                backgroundColor: `${hover}`,
                color: `${white}`,
                cursor: 'pointer'
            },

        }), menu: (provided, state) => ({
            ...provided,
            color: '#888888',
            backgroundColor: state.isSelected ? `${white}` : white,
            margin: '15px 0 0 0',
            border: `1px solid ${hover}`,
            padding: 0,
            borderRadius: 10,
            fontSize: 12,
            zIndex: 10,
            borderBottom: '1px solid #888888'

            // width: 200,
        }), menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? `${white}` : white,
            borderRadius: 10,
            borderColor: `${text}`,
            fontWeight: '400',
            color: '#FFF',
            padding: 0,
            fontSize: 12,
        }),

    };


    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };
    const CaretDownIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
                <g id="Arrow" transform="translate(17238.414 7077.914)">
                    <line id="Line_3886" data-name="Line 3886" x2="5" y2="5" transform="translate(-17237 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
                    <line id="Line_3887" data-name="Line 3887" x1="5" y2="5" transform="translate(-17232 -7076.5)" fill="none" stroke="#040504" strokeLinecap="round" strokeWidth="2"/>
                </g>
            </svg>

        );
    };
    const showSetting = useShowSetting();

    return (<StyledListWithForm pt={pt} id={`${id ? id : 'ListWithForm'} `}

                                className={`list_with_form ${padding ? padding : ''} `}>

        <Container fluid >
            <Row>

                <Col md={6} className={'pr-0'}>
                    <ImageSocialBox title={leftTitle} subtitle={subtitle} showSetting={showSetting} offset={offset} img={'/images/dynamic/common/form-modal.jpg'}/>
                </Col>
                <Col className={'form-column'} md={6}>
                    <div className="">

                    </div>
                    <div className="form_wrapper" style={{paddingRight: offset + 'px'}}>
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                            <input name={'spam_protector'} type="hidden"/>
                            <input name={'form_id'} value={'ieilts-form'} type="hidden"/>
                            <div className="form-group">
                                <Form.Group controlId="formBasicEmail">

                                    <Form.Control
                                        className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register('name', {
                                            required: 'Please enter your full name',
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: 'Name must contain only letters',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                    {errors.name && <span className="error-message form-error">{errors.name.message}</span>}

                                </Form.Group>
                            </div>

                            <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register('email', {
                                                    required: {
                                                        value: true, message: 'please enter your email'
                                                    }, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Please enter a valid email address'
                                                    }
                                                })}
                                                type="email"
                                                placeholder="Email*"/>
                                            {errors.email && <span className="error-message form-error">{errors.email.message}</span>}

                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <Form.Group controlId="formBasicPhone">

                                            <Form.Control
                                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register('phone', {
                                                    required: {
                                                        value: true, message: 'Please enter your phone first'
                                                    }, pattern: {
                                                        value: /^01[0-9]{9}$/,
                                                        message: 'Please enter a valid 11 digit phone number'
                                                    }
                                                })}
                                                type="number"
                                                placeholder="Phone Number*"/>
                                            {errors.phone && <span className="error-message form-error">{errors.phone.message}</span>}

                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>

                            <TextInput
                                name="qualification"
                                placeholder="Highest Qualification"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Highest Qualification',
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: 'Qualification must contain only letters',
                                    },
                                }}
                                error={errors.qualification}
                            />
                            <div className="form-group">
                                <Form.Group controlId="formBasicPhone">
                                    <Select
                                        {...register('contact_method', {
                                            required: {
                                                // value:true,
                                                message: 'Preferred Contact Method*',
                                            },
                                        })}
                                        classNamePrefix="filter"
                                        ref={contactRef}
                                        isSearchable={false}
                                        isClearable={true}
                                        options={contactType}
                                        onChange={e => {
                                            handleContact(e);
                                        }}
                                        placeholder={'Preferred Contact Method*'} styles={customStyles}
                                        components={{DropdownIndicator}}
                                    />
                                </Form.Group>
                            </div>

                            <div className="form-group">
                                <Form.Group controlId="formBasicPhone">
                                    <Select
                                        {...register('interested', {
                                            required: {
                                                // value:true,
                                                message: 'You are Interested in*',
                                            },
                                        })}
                                        classNamePrefix="filter"
                                        ref={interestRef}
                                        isSearchable={false}
                                        isClearable={true}
                                        options={interestType}
                                        onChange={e => {
                                            handleContact(e);
                                        }}
                                        placeholder={'You are Interested in*'} styles={customStyles}
                                        components={{DropdownIndicator}}
                                    />
                                </Form.Group>
                            </div>

                            <div className="form-group">
                                <Form.Group controlId="formBasicPhone">
                                    <Select
                                        {...register('migration', {
                                            required: {
                                                // value:true,
                                                message: 'Why are you interested in taking an English proficiency test?*',
                                            },
                                        })}
                                        classNamePrefix="filter"
                                        ref={migrationRef}
                                        isSearchable={false}
                                        isClearable={true}
                                        options={migrationType}
                                        onChange={e => {
                                            handleContact(e);
                                        }}
                                        placeholder={'Why are you interested in taking an English proficiency test?*'} styles={customStyles}
                                        components={{DropdownIndicator}}
                                    />
                                </Form.Group>
                            </div>


                            <div className="note">
                                <p>* We will not share your details with others</p>
                            </div>
                            <div className={'form-group '}>
                                <div onClick={handleSubmit(onSubmit, onError)}>

                                    <Button height={45} borderRadius={25} width={'210px'} text={'Submit Message'}/>

                                </div>

                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
    box-sizing: border-box;
    position: relative;
    margin-top: -1px;
    padding-top: ${(props) => (props.pt ? props.pt + 'px' : '0')};

    .container-fluid {
        padding-left: 0;
    }

    .dc-btn a {
        border: none;
    }

    .single_image_image_wrp {
        padding-top: unset !important;

        @media (max-width: 992px) {
            padding-top: 108.772% !important;
        }
    }


    .social {
        a {
            &:hover {
                path {
                    fill: ${text} !important;
                }
            }
        }
    }


    .note {
        margin-bottom: 30px;

        p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .form_wrapper {
        padding: 70px 0 70px 70px;
        height: 100%;
        position: relative;
        // overview_bg

        .left-col {
            div {
                height: 100%;
            }
        }


        form {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .textarea {
            min-height: 120px;
        }

        .title {
            font-size: 24px;
            font-weight: 4000;
            line-height: 28px;
            color: #F9F9F9;
            margin: 0 0 40px;
        }

        .form-group {
            margin-bottom: 30px;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                display: block;
            }

            .form-control {
                box-shadow: none;
                outline: 0;
                border-radius: 0;
                background: transparent;
                height: auto;
                border-left: none;
                border-right: none;
                border-top: none;
                border-color: rgba(167, 161, 159, 0.5);
                padding: 0 0 8px;
                color: ${text};
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.16px;
                margin-bottom: 0;

                svg {
                    display: block;
                }

                &:focus {
                    border-color: ${text};

                }

                &::placeholder {
                    color: rgba(167, 161, 159, 0.5);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.16px;

                }
            }
        }


    }

    .row {
        .form-group {
            margin-bottom: 40px !important;
        }
    }

    .form-column {
        background: ${white};
    }


    .form_wrapper {
        .row {
            margin: 0;
            width: 100%;
            gap: 15px;
            justify-content: space-between;

            .col-md-6 {
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
                padding-left: 0;
                padding-right: 0;
            }
        }
    }


    //selector

    .filter__menu{
        border: 1px solid ${white} !important;
    }
    .filter__control {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top: none;
        padding-left: 0;
        background: ${white};
        cursor: pointer;

        .filter__value-container {
            padding-left: 0;
            padding-right: 0;
        }

        .filter__single-value {
            color: ${text};
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__placeholder {
            color: rgba(167, 161, 159, 0.5);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__indicator-separator {
            display: none;
        }

        .filter__indicator {
            padding-right: 0;

            svg{
                line{
                    stroke:  rgba(167, 161, 159, 0.5);
                }
            }
        }
    }

    .filter__control--is-focused {
        outline: none;
        border-bottom: 1px solid rgba(167, 161, 159, 0.5) !important;
        box-shadow: none;
    }


    @media (max-width: 992px) {
        transform: none;
        .social {
            align-items: flex-start !important;
            justify-content: flex-start !important;
        }

        .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .container-fluid {
            padding-left: 0 !important;
        }

        .single_content_wrp {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }

        .form_wrapper {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }
    }
`;

export default React.memo(ContactForm);
